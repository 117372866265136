<template>
  <div class="container">

<div class="main">
  <!-- <yanzheng /> -->
  <div class="zezhao" v-if="zezhao||weihu">
    <div class="zezhao_tip" v-if="zezhao">
      <section class="zz-header">温馨提示</section>
      <div class="zz_content">
        <section style="height:1px"></section>
        <img src="../../assets/closewarn.png" class="closewarn" alt="">
        <h2>{{currentGame.Gamename}}现已关盘</h2>
        <p>下期开盘时间为</p>
        <p class="green">{{kaipanTime}}</p>
      </div>
    </div>
    <div class="zezhao_tip" v-if="weihu">
      <section class="zz-header">温馨提示</section>
      <div class="zz_content">
        <section style="height:1px"></section>
        <img src="../../assets/closewarn.png" class="closewarn" alt="">
        <h2>{{currentGame.Gamename}}正在维护</h2>

      </div>
    </div>

  </div>
  <top-timer></top-timer>
  <!-- <div class="dohand">
    <div class="preset">预设</div>
    <div class="amount">金额<input type="text" /></div>
    <div class="btn none">确认</div>
    <div class="btn yes">重置</div>
    <div class="btn2 a">结果走势</div>

  </div> -->
  <div class="player listmain type-sxg lgx5 game-lgx5 game-sxg-lm">
    
    <!-- 平码 -->
    <template v-for="(item,index) in rowsData">
      <div class="data" :class="'cZ'+index+1" v-if="index<5"  :key="item.title">
        <h3>{{item.title}}</h3>
        <ul>
          <li v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
            <ol style="width:44px" class="td_name">{{ele.label}}</ol>
            <ol class="td_rate">{{ele.Odds}}</ol>
            <ol class="td_cash">
              <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money">
              <div class="quick-check" v-if="ele.b">
                <ul>
                  <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                    下注{{ qs.num }}元
                  </li>

                  <li @click="closeCheck(ele)">关闭</li>
                </ul>
              </div>
            </ol>
          </li>

        </ul>
      </div>
    </template>
    <div class="data w25" >
      <h3>前五和值</h3>
      <ul>
          <li v-for="ele in rowsData[5].arr" :key="ele.ResultID" :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)">
            <ol style="width:44px" class="td_name">{{ele.label}}</ol>
            <ol class="td_rate">{{ele.Odds}}</ol>
            <ol class="td_cash">
              <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money">
              <div class="quick-check" v-if="ele.b">
                <ul>
                  <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                    下注{{ qs.num }}元
                  </li>

                  <li @click="closeCheck(ele)">关闭</li>
                </ul>
              </div>
            </ol>
          </li>

        </ul>
    </div>
    <!-- --- -->
  </div>
  <!-- 预设 -->
  <yushe />
  <!--表格  -->
  

</div>
<div class="other">
  <div class="zezhao" v-if="zezhao||weihu"></div>
  <div class="right-com">
    <div class="rc-tab">
      <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
      <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
    </div>
    <div class="rc-tab-long" v-if="changelongActive=='1'">
      <div class="long-isbz">
        <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
        <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
      </div>
      <ul class="longlist" v-if="kaiActive=='1'">
        <li v-for="item in rclData" @click="selectedChangLong(item)" :key="item.ID">
          <span>{{item.info}}</span>
          <b class="red">{{item.num}}期</b>
        </li>
      </ul>
      <ul class="longlist" v-if="kaiActive=='2'">
        <li v-for="item in lclData" @click="selectedChangLong(item)" :key="item.ID">
          <span>{{item.info}}</span>
          <b class="blue">{{item.num}}期</b>
        </li>
      </ul>
    </div>

  </div>
</div>
</div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'qwlm',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
     
        { title: "平码一", arr: [] },
        { title: "平码二", arr: [] },
        { title: "平码三", arr: [] },
        { title: "平码四", arr: [] },
        { title: "特码(第五球)", arr: [] },
        {title:'前五和值',arr:[]}
      ],
      labelArr1: [
        "合值大",
        "合值单",
        "合值尾大",
        "龙",
        "合值小",
        "合值双",
        "合值尾小",
        "虎",
      ],
      labelArr2: [ ],
      labelArr:["单", "双","大", "小","尾大", "尾小", "合单", "合双",'福','禄','寿','喜' ],
      labelArr3:["单", "双","大", "小","尾大", "尾小", "龙", "虎"],
    };
  },

  components: {
    topTimer,
    yushe,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let title=item.title;
                if(ele.label=='龙'||ele.label=='虎'){
                  title=''
                }
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };

                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // console.log(resAArr,173)
      
        // 平码一
        let arr2 = this.ddFilterData1(resAArr, [5372,5373,5370,5371,5374,5375,5376,5377,21831,21832,21833,21834], this.labelArr );
        this.rowsData[0].arr = arr2;
        // // 平码二
        let arr3 = this.ddFilterData1(resAArr, [5380,5381,5378,5379,5382,5383,5384,5385,21835,21836,21837,21838], this.labelArr);
        this.rowsData[1].arr = arr3;
        // // 平码三
        let arr4 = this.ddFilterData1(resAArr, [5388,5389,5386,5387,5390,5391,5392,5393,21839,21840,21841,21842], this.labelArr);
        this.rowsData[2].arr = arr4;
        // // 平码四
        let arr5 = this.ddFilterData1(resAArr, [5396,5397,5394,5395,5398,5399,5400,5401,21843,21844,21845,21846], this.labelArr);
        this.rowsData[3].arr = arr5;
        // // 特码
        let arr6 = this.ddFilterData1(resAArr, [5404,5405,5402,5403,5406,5407,5408,5409,21847,21848,21849,21850], this.labelArr);
        this.rowsData[4].arr = arr6;
        // 和值
        let arr7=this.ddFilterData1(resAArr,[5366,5367,5364,5365,5368,5369,5418,5419],this.labelArr3)
        this.rowsData[5].arr = arr7;
        // console.log(this.rowsData)
        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    selectedChangLong(item1){
      if(this.ISOPEN){
        this.rowsData.forEach(item=>{
        item.arr.forEach(ele=>{
        if(ele.ResultID==item1.itemid){
          ele.selected=!ele.selected
        }
        })
      })
      }
     
      // console.log(item1,240)
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    clear: both;
}
.listmain > .data.clst > ul.custom_ul {
  width: 100%;
  height: 52px;
  display: flex;

  flex-flow: column wrap !important;
}
 
.listmain .cZ41{
  
    width: calc((100% - 8px) / 5);
}
.listmain .data.w25 ul li{
  width: 25%;
}
  
 
.listmain .cZ01,.listmain .cZ11,.listmain .cZ21,.listmain .cZ31{
  margin-right: 2px;
    width: calc((100% - 8px) / 5);
}
</style>
